import './index.less'
import React, { Component } from 'react'
import ReactFullpage from '@fullpage/react-fullpage';
import Loading from '../loading';
import Header from '../../components/header';
import Sider from '../../components/sider';
import Homemenu from '../../components/menu';
import MemberShip from '../membership';
import NFTstore from '../NFTstore';
import Tokenomics from '../tokenomics';
import GenesisNft from '../genesisNft';
import OtherNft from '../otherNfts';
import PropertyNft from '../propertyNft';
import Footer from '../../components/footer';
import Modal from '../../components/modal';
export default class Index extends Component {
  constructor (props) {
    super(props)
    this.state = {
        result:[],
        index:1,
        showLoading:true,
        showMenu:false,
        showMember:false,
        showStore:false,
        showNftRoom:false,
        showTokenOmics:false,
        showGenesisNft:false,
        showPropertyNft:false,
        showOhterNft:false,

        page1Show:false,
        page2Show:false,
        page3Show:false,
        page4Show:false,
        page5Show:false,
        page6Show:false,
        page7Show:false,
        page8Show:false,
        page9Show:false,
        tokenCircleClass:'token-circle animate__animated',
        page:'index',
        showModal:false,

    }
  }
 
  componentDidMount () {
    const page = this.getUrl('page');
    let index = 0;
    switch(page){
      case 'index':
        index = 1
        break;
      case 'game-play':
        index = 3
        break;        
      case 'socialfi':
        index = 4
        break;
      case 'nft-showroom':
        index = 5
        break;
      case 'tokenomics':
        index = 6
        break;
      case 'roadmap':
        index = 7
        break;
      case 'founding-team':
        index = 8
        break;
      case 'our-parteners':
        index = 9
        break;        

      case 'member':
        this.openMember(true)
        break;   

      default:
    }
    window.fullpage_api.moveTo(index)
    this.setState({
      page:page,
    },()=>{
      console.log(this.state.page)
    })
    
    if(this.state.showLoading===true){
      const timer = setTimeout(() => {
        this.setState(
          { showLoading:false },
          () => {    
              
          }
        );
      }, 3000);
      return () => {
        clearInterval(timer);
      };    
    }
  }
  
  onRefA = ref =>{
    this.childA = ref
  }
  onRef = ref =>{
    this.child = ref
  }
  getUrl= () =>{
    const str = window.location.href
    const reg = /([^?&=]+)=([^&]+)/g  // 分组捕获 两个子组
    const params = {}
    str.replace(reg, (_, k, v) => params[k] = v)
    if(JSON.stringify(params) ==='{}'){
      return 'index'
    }else{
      return params.page
    }
   }
  openMenu = value => {
    this.setState(
      { showMenu: value,showLoading:false },
      () => {    
          console.log(this.state.showMenu);
      }
    );
  }
  openModal = value => {
    this.setState(
      { showModal: value},
      () => {    
          console.log(this.state.showModal);
      }
    );
    
  }
  openMember = value => {
    // const showMember = this.state.showMember

    // if(showMember===true){
    //   this.setState(
    //     { showMember: false, },
    //     () => {    
    //         console.log(this.state.showMember);
    //     }
    //   );
    //   return false
    // }
    this.setState(
      { showMember: value, showNftRoom:false,showStore:false,showTokenOmics:false,showGenesisNft:false,showLoading:false,showMenu:false,showPropertyNft:false,showOhterNft:false},
      () => {    
          console.log(this.state.showMember);
      }
    );
  }
  toPage = value => {
    this.setState(
      { showMenu: false,showMember:false,showNftRoom:false,showStore:false,showTokenOmics:false,showGenesisNft:false,showPropertyNft:false,showOhterNft:false },
      () => {    
          console.log(this.state.showMenu);
      }
    );
   
    window.fullpage_api.moveTo(value)
  }
  backHome= ()=>{
    this.setState(
      { showMenu: false,showMember:false,showNftRoom:false,showStore:false,showTokenOmics:false,showGenesisNft:false,showPropertyNft:false,showOhterNft:false },
      () => {    
          console.log(this.state.showMenu);
      }
    );
  }
  goNftStore(){
    this.setState(
      { showStore: true },
      () => {
          console.log(this.state.showStore);
      }
    );
  }
  goTokenomics(){
    this.setState(
      { showTokenOmics: true },
      () => {
          console.log(this.state.showTokenOmics);
      }
    );
  }
  goGenesisNft(){
    this.setState(
      { showGenesisNft: true },
      () => {
          console.log(this.state.showGenesisNft);
      }
    );
  }
  goPropertyNft(){
    this.setState(
      { showPropertyNft: true },
      () => {
          console.log(this.state.showPropertyNft);
      }
    );
  }
 goOtherNft(){
    this.setState(
      { showOhterNft: true },
      () => {
          console.log(this.state.showOhterNft);
      }
    );
  }

  afterLoad(origin, destination, direction) {
    console.log('afterLoad', { origin, destination, direction });
    let _class = "token-circle animate__animated"
    if(destination.index===5){
      _class = "token-circle  animate__animated fadeInDelay"
    }
  
    this.child.changeIndex(destination.index)
    this.childA.changeShow(false)
    
  
    this.setState(
        { index: destination.index,
          tokenCircleClass:_class
        },
        () => {
            console.log(this.state.index);
        }
    );
  
  }
  goOutLink(url){
    window.open(url,'_blank')
  }
  tokenCircle(){
    let _class = "token-circle animate__animated  router360";
    let _classA = "token-circle animate__animated show";
    const _this = this
    const tokenCircleClass = this.state.tokenCircleClass
    if(tokenCircleClass==='token-circle animate__animated  router360'){
      return false
    }
    this.setState(
      { 
        tokenCircleClass:_class
      },
      () => {
          //console.log(this.state.index);
      }
    );


    let time = 2;
    for (let i = 1; i <= time; i++) {
        setTimeout(function () {
            if (i === time) {
              _this.setState(
                { 
                  tokenCircleClass:_classA
                },
                () => {
                    
                }
              );
                
                return false;
            }
        }, 1000 * i)
    }
  }
  onLeave(origin, destination, direction) {
    console.log('onLeave', { origin, destination, direction });
    this.setState(
        { showLoading: false},
        () => {
            
        }
    );
   
  }
  render () {
    return (
      <div className='main-area'> 
        { this.state.showLoading===false? '':(<Loading  />)}
        <Header onRef={this.onRefA}  openMember={this.openMember}  openMenu = {this.openMenu} openModal = {this.openModal}/>
        <div className='main-content'>
          <Sider onRef={this.onRef}  />
          { this.state.showMenu===false? '':(<Homemenu onRef={this.onRef}  openMember={this.openMember} openModal = {this.openModal}ß toPage= {this.toPage}/>)}
          { this.state.showMember===false? '':(<MemberShip backHome={this.backHome} />)}
          { this.state.showStore===false? '':(<NFTstore backHome={this.backHome} />)}
          { this.state.showTokenOmics===false? '':(<Tokenomics backHome={this.backHome} />)}
          { this.state.showGenesisNft===false? '':(<GenesisNft backHome={this.backHome} />)}
          { this.state.showPropertyNft===false? '':(<PropertyNft backHome={this.backHome} />)}
          { this.state.showOhterNft===false? '':(<OtherNft backHome={this.backHome} />)}
          <ReactFullpage
            //上面的参数设置都写在这里，不用再写在构造函数里面
            credits= { {enabled :false,label: '', position: 'right'}}
            openModal = {this.openModal}
            navigation={false}
            onLeave={this.onLeave.bind(this)}
            fadingEffect={'slides'}
            afterLoad={this.afterLoad.bind(this)}
            sectionsColor={['#1a1a1a', '#1a1a1a', '#1a1a1a', '#1a1a1a','#1a1a1a', '#1a1a1a', '#1a1a1a', '#1a1a1a', '#1a1a1a']}
            resetSliders = {true}// 在离 section 后重置每个滑块
            //parallax={true}
            resize={true}
            render={({state, fullpageApi}) => {
              return (
                <ReactFullpage.Wrapper>
                  
                  <div className="section page1">
                    <div className={['welcome-to-the-new-world','animate__animated',this.state.index===0?'animate__zoomInRight':''].join(' ')}>Welcome To The New World</div>
                    <div  onClick={()=>this.goOutLink('https://www.youtube.com/watch?v=SWqxX-q1Nm8')} className={['ibutton','video','animate__animated',this.state.index===0?'animate__fadeInRight':''].join(' ')}></div>
                    <div className={['s-tip','animate__animated',this.state.index===0?'animate__fadeInUp':''].join(' ')}>Where virtual reality transcends boundaries, dreams become reality, and limitless adventures await.</div>
                  </div>
                  <div className='section page2'>
                    <div className={['page2-bg','animate__animated',this.state.index===1?'animate__fadeInRight':''].join(' ')}></div>
                    
                    <div className={['s-tip','animate__animated',this.state.index===1?'animate__fadeInUp':''].join(' ')}>
                      <li>Where Friendship, Creativity, </li>
                      <li>And Endless Giggles Come Together</li>
                      <li>In A Digital Wonderland.</li>
                      <li>Let The Fun Begin! </li>
                    </div>
                    <div className={['unlease','animate__animated',this.state.index===1?'animate__fadeInRight':''].join(' ')}>
                        <li>Unleash Your</li>
                        <li>Inner Sparkle</li>
                        <li>And Join Infiblue</li>
                        <li>World Socialfi</li>
                    </div>
                  </div>
                  <div className="section page4">
                    <h1 className={['title','animate__animated',this.state.index===2?'animate__fadeInUp':''].join(' ')}>GAME PLAY</h1>
                    <div className='demo' onClick={()=>this.goOutLink('/demo/')}></div>
                    <div className='con'>
                      <div className='left'>
                        <ul className={['g-list','animate__animated',this.state.index===2?'animate__fadeInUp':''].join(' ')}>
                          <li className='item'>
                            <div className='no'>01</div>
                            <div className='v'>Free To Start</div>
                          </li>
                          <li className='item'>
                            <div className='no'>02</div>
                            <div className='v'>Get A Virtual Job And Get Paid</div>
                          </li>
                          <li className='item'>
                            <div className='no'>03</div>
                            <div className='v'>Be A Wise Investor</div>
                          </li>
                          <li className='item'>
                            <div className='no'>04</div>
                            <div className='v'>Fully Community Driven</div>
                          </li>
                          <li className='item'>
                            <div className='no'>05</div>
                            <div className='v'>Our Game Mirrors Real World Economics</div>
                          </li>
                          <li className='item'>
                            <div className='no'>06</div>
                            <div className='v'>A World Unfolds Itself On Real Map</div>
                          </li>
                          <li className='item'>
                            <div className='no'>07</div>
                            <div className='v'>Decentralized Server And Database</div>
                          </li>
                          <li className='item'>
                            <div className='no'>08</div>
                            <div className='v'>Community Governance</div>
                          </li>
                        </ul>
                      </div>
                      <div className='right' >
                        <iframe  className={['phone-u','game-demo','animate__animated',this.state.index===2?'animate__fadeInRight':''].join(' ')}  title='game dome' src='/demo/'>

                        </iframe>
                        {/* <div className={['phone-u','animate__animated',this.state.index===2?'animate__fadeInRight':''].join(' ')}></div> */}
                      </div>
                    </div>

                  </div>                
                  <div className="section page3">
                    <div onClick={()=>this.openModal(true)} className={['ibutton','download-here-m','animate__animated',this.state.index===3?'animate__fadeInUpBig':''].join(' ')}></div>

                    <div className='left'>
                        <div className={['phone-u','animate__animated',this.state.index===3?'animate__fadeInUpBig':''].join(' ')}></div>
                        <div className={['phone-t','animate__animated',this.state.index===3?'animate__fadeInBottomLeft':''].join(' ')}></div>
                        
                    </div>
                    <div className='right'>
                        <div className={['title','animate__animated',this.state.index===3?'animate__fadeInRight':''].join(' ')}>
                          <div className='t'>INFIBLUE WORLD</div>
                          <div className='s-t'>-INFIBLUE CHAT-</div>
                          <div className='s-t-m'></div>
                           
                          </div>
                        <div className={['s-tip','animate__animated',this.state.index===3?'animate__fadeInUp':''].join(' ')}>Infiblue World Socialfi Ecosystem Allows Creators Turn Their Passion Into Profit By Captivating Communities With Compelling Content And Unlocking Endless Opportunities To Earn Lucrative Rewards. Let’s Start Making Money By Social Networking! </div>
                        <div onClick={()=>this.openModal(true)} className={['ibutton','download-here','animate__animated',this.state.index===3?'animate__fadeInUpBig':''].join(' ')}></div>
                    </div>

                  </div>

                  <div className="section page5">
                    <h1 className={['title','animate__animated',this.state.index===4?'animate__fadeInLeft':''].join(' ')}>NFT SHOWROOM</h1>
                    <div className='con'>
                      <div className='item a'>
                          <div className='s-n'>GENESIS NFT</div>
                          <div className='ss-n'>The Genesis Nfts
                            From The Best
                            Metaverse Game</div>
                          <div className='ibutton more-info'   onClick={()=>this.goGenesisNft()}></div>
                      </div>
                      <div className='item b'>
                          <div className='s-n'>Property NFTS</div>
                          <div className='ss-n'>The Genesis Nfts
                            From The Best
                            Metaverse Game</div>
                          <div className='ibutton more-info'   onClick={()=>this.goPropertyNft()}></div>
                      </div>                     
                      <div className='item c'>
                          <div className='s-n'>SOCIALFI NFT</div>
                          <div className='ss-n'>EXODUS NFT for Infiblue Chat</div>
                          <div className='ibutton more-info'   onClick={()=>this.goOutLink('https://medium.com/@InfiblueWorld/unleashing-the-power-of-infiblue-exodus-nfts-transforming-the-infiblue-socialfi-experience-c82604deb612')}></div>
                      </div> 
                      <div className='item d'>
                          <div className='s-n'>OTHER NFTS</div>
                          <div className='ss-n'>Imagination meets reality in Infiblue World</div>
                          <div className='ibutton more-info'   onClick={()=>this.goOtherNft()}></div>
                      </div> 
                      {/* <div className={['item','nft','animate__animated',this.state.index===4?'animate__fadeInUp':''].join(' ')}>
                          <div className='s-n'>GENESIS NFT</div>
                          <div className='ss-n'>The Genesis Nfts
                            From The Best
                            Metaverse Game</div>
                          <div className='ibutton more-info'   onClick={()=>this.goGenesisNft()}></div>

                      </div>
                      <div  className={['item','place','animate__animated',this.state.index===4?'animate__fadeInUp':''].join(' ')}>
                          <div className='s-n'>Property NFTS</div>
                          <div className='ss-n'>Coming soon...</div>
                          <div className='ibutton more-info-disabled'></div>
                      </div> */}
                    </div>
                    <div className={['ibutton','nft-store','animate__animated',this.state.index===4?'animate__fadeInLeft':''].join(' ')}  onClick={()=>this.goNftStore()}></div>
                  </div>
                  <div className="section page6">
                    <h1 className={['title','animate__animated',this.state.index===5?'animate__fadeInUp':''].join(' ')}>TOKENOMICS</h1>
                    <img alt="" className={['token','animate__animated',this.state.index===5?'rotateInX':''].join(' ')} src="images/token-bg.svg"></img>
                    <img alt="" onClick={() => this.tokenCircle() }  className={this.state.tokenCircleClass} src="images/token-circle.svg"></img>
                    <div className='con'>
                      <div className={['ibutton','more-info','animate__animated',this.state.index===5?'animate__fadeInDown':''].join(' ')}  onClick={()=>this.goTokenomics()}></div>
                      <div className={['tip','animate__animated',this.state.index===5?'animate__fadeInDown':''].join(' ')}>
                        <li className='item'>Monie is the main Infiblue token. </li>
                        <li className='item'>It is a governance token running on the</li>
                        <li className='item'>Binance Smart Chain (BSC) for community governance, </li>
                        <li className='item'>voting, and purchasing NFT assets.</li>
                      </div> 
                  </div>
                  </div>
                  <div className={['section','page7','animate__animated',this.state.index===6?'bgChangeY':''].join(' ')}>
                    <img alt="" className={['roadmap','animate__animated',this.state.index===6?'fadeBigIn':''].join(' ')} src="images/roadmap.svg"></img>  
                    <div className='ibutton download' onClick={() => this.goOutLink('./roadmap.pdf') } ></div>
                  </div>
                  <div className="section page8">
                    <h1 className={['title','animate__animated',this.state.index===7?'animate__fadeInRight':''].join(' ')}>founding team</h1>
                    <div className={['con','animate__animated',this.state.index===7?'animate__fadeInRight':''].join(' ')}>
                        <div className='item a'>
                          <div className='name'>Nick</div>
                          <div className='sub'>Blockchain Developer</div>
                          <div className='intro'>8 Years Experience In Software Development; A Blockchain Fanatic With 5 Years Of Blockchain Development Experience; Master’s Degree From Purdue University. </div>
                        </div>
                        <div className='item b'>
                          <div className='name'>Aney</div>
                          <div className='sub'>Marketing</div>
                          <div className='intro'>A serial entrepreneur of direct to consumer products; Founded a YC backed computer vision company; Led product at Walmart Labs, Disney+, and Amazon.</div>
                        </div>
                        <div className='item c'>
                          <div className='name'>Howard</div>
                          <div className='sub'>Front-end Developer</div>
                          <div className='intro'>10 Years Experience In Ios, Android, And Web Development;mobile Tech Lead At Uber; Master’s Degree From New York University. </div>
                        </div>
                        <div className='item d'>
                          <div className='name'>Zack</div>
                          <div className='sub'>Back-end Develope</div>
                          <div className='intro'>12 Years Experience In Backend Distributed System Development;  Senior Software Engineer, Tech Lead At Meta (Facebook); Master’s Degree From New York University.</div>
                        </div>
                        <div className='item e'>
                          <div className='name'>George</div>
                          <div className='sub'>Consultant-game and 3D design</div>
                          <div className='intro'>8 years experience iOS / Android mobile game production; 3D Art Lead with 5+ shipped titles; experience in Unity Engine creating 3D characters and environments. Background in design, animation, 3D modeling, texturing, rigging, lighting, and digital art. BFA Ringling College of Art + Design.</div>
                        </div>
                        <div className='item f'>
                          <div className='name'>Joyhoo</div>
                          <div className='sub'>Product Designer</div>
                          <div className='intro'>8 years of digital product and interaction design experience in FinTech, e-commerce, and tech industries. Lead design 10 consumer iOS and Android apps; Design the No.1 paid weather app;  Product designer at Uber.</div>
                        </div>
                        <div className='item g'>
                          <div className='name'>Tim</div>
                          <div className='sub'>Consultant-game & Software Engineer</div>
                          <div className='intro'>8 years experience in video game development; 5 years experience in VR and 3D graphics related areas; Previously worked for game companies such as EA and Activision; Familiar with game engines like Unreal and Unity.</div>
                        </div>
                        <div className='item h'>
                          <div className='name'>Diana</div>
                          <div className='sub'>Chief Marketing Officer</div>
                          <div className='intro'>Working experience at PwC & Aave, founded of GameFi project in 2021, rich experience in Web3 marketing. A Huge NFT enthusiast, and a proud mom of a CryptoKitty. Advisor & early-investor for multiple Web3 projects. Master Degree from Vaasa University.</div>
                        </div>
                        
                      
                    </div>
                  </div>
                  <div className="section page9">
                    <h1  className={['title','animate__animated',this.state.index===8?'animate__fadeInLeft':''].join(' ')}>our partners</h1>
                    <div  className={['con','animate__animated',this.state.index===8?'animate__fadeInLeft':''].join(' ')}>
                      <div className='arrayA'>
                        
                        <img alt="" className="partner" src="images/parntners/bitmart.svg"></img>
                        <img alt="" className="partner" src="images/parntners/BNB.svg"></img>
                        <img alt="" className="partner" src="images/parntners/CERTIK.svg"></img>                     
                      </div>
                      <div className='arrayB'>
                        <img alt="" className="partner" src="images/parntners/MEXC.svg"></img>
                        <img alt="" className="partner" src="images/parntners/Pancake.svg"></img>
                        <img alt="" className="partner" src="images/parntners/RAWRR.svg"></img>

                      </div>
        
                    </div>
                    <div className='pfooter'>
                      <div className={['fmenu','animate__animated',this.state.index===8?'animate__fadeIn':''].join(' ')}>
                        <div className='left'>
                            <div className='socials'>
                            
                            </div>
                            <div className='ibutton playnow'></div>
                            <div className='ibutton ios'></div>
                            <div className='ibutton googleplay'></div>
                            
                        </div>
                        <div className='right'>
                            <div className='top'>
                              <div className='totop' onClick={()=>fullpageApi.moveTo(1)}></div>
                            </div>
                            <div className='c-info'>
                              <div className='sub'>
                                <div className='item' onClick={()=>fullpageApi.moveTo(1)}>PROJECT</div>
                                <div className='item' onClick={()=>fullpageApi.moveTo(5)}>NFTS</div>
                                <div className='item' onClick={()=>fullpageApi.moveTo(6)}>tokenomics</div>
                                <div className='item' onClick={()=>fullpageApi.moveTo(7)}>ROADMAP</div>
                                <div className='item' onClick={()=>fullpageApi.moveTo(8)}>TEAM</div>
                              </div>
                              <div className='sub contact'>
                                <div className='item'>CONTACT US</div>
                                <div className='email'>info@infiblue.world</div>

                              </div>

                            </div>

                            
                        </div>
                      </div>
                      <div className='copy-area'>
                        <div className='socials'>
                          <div className='icon twitter' onClick={() => this.goOutLink('https://twitter.com/InfiblueNFT') }></div>
                          {/* <div className='icon game' onClick={() => this.goOutLink('https://discord.gg/svyR68KhXJ') }></div>
                          <div className='icon telegram' onClick={() => this.goOutLink('https://t.me/infiblueworld') }></div> */}
                        </div>
                        <div className='copy'>COPYRIGHT © 2022. ALL RIGHTS RESERVED BY INFIBLUE</div>                      
                      </div>

                    </div>
                    <div className='pfooter-m'>
                        <div className='icon twitter' onClick={() => this.goOutLink('https://twitter.com/InfiblueNFT') } ></div>
                        {/* <div className='icon game' onClick={() => this.goOutLink('https://discord.gg/svyR68KhXJ') }></ div>
                        <div className='icon telegram' onClick={() => this.goOutLink('https://t.me/infiblueworld') } ></div> */}
                    </div>
                  </div>


                </ReactFullpage.Wrapper>
              );
            }}
          />
        </div>
        { this.state.index===8? '':(<Footer />)}
        { this.state.showModal? (<Modal openModal = {this.openModal}/>):''}
      </div>
    )
  }
}