import React, { Component } from 'react'
import './index.less'
class OtherNfts extends Component {
    constructor (props) {
        super(props)
        this.state = {
            step:1,
        }
        
    }
    goOutLink(url){
        window.open(url,'_blank')
    }
    Home(){
        if(this.props.backHome!==undefined){
            this.props.backHome()
        }
    }
    render() {
        return (
            <div className='genesisB'>
                <div className='back-home' onClick={()=>this.Home()}></div>
                <div className='step step1'>
                    <div className='switch'>     
                    <div className='mid'>
                        <div className='item a'>
                            <div className='img-area'>
                                
                            </div>
                            <div className='name'>INFIBLUE HAT</div>
                            <div className='status'>Quantity:</div>
                            <div className='status'>Normal 5000</div>
                            <div className='status'>Rare 500</div>
                            
                        </div>
                        <div className='item b'>
                            <div className='img-area'>
                               
                            </div>
                            <div className='name'>RAWRR </div>
                            <div className='status'></div>
                            <div onClick={() => this.goOutLink('https://medium.com/@InfiblueWorld/get-ready-to-cruise-in-style-rawrr-electric-all-terrain-bikes-are-coming-to-infiblue-world-91e23f411772') } className='ibutton learn-more'></div>
                        </div>                
                    </div>
                    </div>
                </div> 
            </div>
        )
    }
}

export default OtherNfts