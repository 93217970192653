import React, { Component } from 'react'
import './index.less'
class Tokenomics extends Component {
    constructor (props) {
        super(props)
        this.state = {
            step:1,
        }
    }
    Home(){
        if(this.props.backHome!==undefined){
            this.props.backHome()
        }
    }
    render() {
        return (
            <div className='tokenomics'>
                <div className='back-home' onClick={()=>this.Home()}></div>
                {
                    this.state.step===1?<div className='step1'>
                        <div className='others' onClick={()=>this.setState({
                            step:2
                        })}>
                            <div className='title'>OTHERS</div>
                            <div className='ilist'>
                                <div className='item'>
                                    <div className='v'>1.55%</div>
                                    <div className='n'>Community Round 1</div>
                                </div>
                                <div className='item'>
                                    <div className='v'>1%</div>
                                    <div className='n'>Seed Sale</div>
                                </div>
                                <div className='item'>
                                    <div className='v'>0.5%</div>
                                    <div className='n'>Private Sale</div>
                                </div>
                                <div className='item'>
                                    <div className='v'>0.5%</div>
                                    <div className='n'>Advisory</div>
                                </div>
                                <div className='item'>
                                    <div className='v'>0.2%</div>
                                    <div className='n'>Community Round 2</div>
                                </div>
                              
                            </div>
                        </div>
                        <div className='chart'  onClick={()=>this.setState({
                            step:2
                        })}>
                            <img alt="" className="chart-img" src="images/bing.png"></img>  
                        </div>
                    </div>:<div className='step2' onClick={()=>this.setState({
                            step:1
                        })}>
                        <div className='top'>
                            <img alt="" className="certik-logo" src="images/certik-logo.svg"></img>
                            <img alt="" className="helicopter" src="images/helicopter.png"></img>
                            
                        </div>
                        <div className='contarct'>
                            <div className='st'>CONTRACT ADDRESS</div>
                            <div className='addr'>0x7E60C74b9096f8FA6fb5a9FD88405dED8B7D44f3</div>
                            <div className='by'>Smart Contracts Audited By Certik</div>
                        </div>

                    </div>
                }
            </div>
        )
    }
}

export default Tokenomics