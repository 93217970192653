import React, { Component } from 'react'
import './index.less'

class Sider extends Component {
    constructor (props) {
        super(props)
        this.state = {
            index:0,
            name:'project'
        }
      }
    componentDidMount(){
        console.log("componentDidMount()")
        if(this.props.onRef!==undefined){
            this.props.onRef(this)
        }
    }
    changeIndex = index =>{
        console.log("changeIndex")
        let name ="project";
        let top= 0;
        switch(index){
            case 0:
                name= 'project';
                top=29.5;
                break;      
            case 1:
                name= 'Social-fi';
                top=79.5;
                break;
            case 2:
                name = 'GAME pLAY'
                top=130.5
                break;
            case 3:
                name= 'Social-fi';
                top=180.5;
                break;
            case 4:
                name = 'NFT SHOWROOM'
                top=230.5
                break;
            case 5:
                name= 'tOKENOMICS';
                top=280.5;
                break;
            case 6:
                name = 'rOADMAP'
                top=380.5
                break;
            case 7:
                name= 'fOUNDING TEAM';
                top=480.5;
                break;
            case 8:
                name = 'OUR PARTNERS'
                top=580.5
                break;
            default:
        }

        this.setState(
            { 
                index: index,
                name:name ,
                top:top
            },
            () => {
                console.log(this.state.index);
            }
        );
    }
    render() {
        return (
            <div className='sider'>
                <div className='current' style={{marginTop:this.state.top+'px'}}>
                    <img alt="" className="arrow" src="images/arrow.svg"></img>
                    <svg className='name'>
                        <text className='text' fill='white' x='16' y='24'>{this.state.name}</text>
                    </svg>
                </div>
                
           </div>
        )
    }
}

export default Sider