import React, { Component } from 'react'
import './index.less'
class NFTstore extends Component {
    constructor (props) {
        super(props)
        this.state = {
            list:[
                {name:'FIRE',status:'Sold out',img:'images/fire.png',price:22,quantity:50,currency:2323,id:1},
                {name:'FIRE',status:'Sold out',img:'images/fire.png',price:22,quantity:50,currency:2323,id:2},
                {name:'FIRE',status:'Sold out',img:'images/fire.png',price:22,quantity:50,currency:2323,id:3},
                {name:'FIRE',status:'Sold out',img:'images/fire.png',price:22,quantity:50,currency:2323,id:4},
                {name:'FIRE',status:'Sold out',img:'images/fire.png',price:22,quantity:50,currency:2323,id:5},
                {name:'FIRE',status:'Sold out',img:'images/fire.png',price:22,quantity:50,currency:2323,id:6},
                {name:'FIRE',status:'Sold out',img:'images/fire.png',price:22,quantity:50,currency:2323,id:7},
                {name:'FIRE',status:'Sold out',img:'images/fire.png',price:22,quantity:50,currency:2323,id:8},

            ],
            option:'Price high to low',
            optionShow:false
        }
    }
    changeOption = type => {
        this.setState(
          { option: type,
          },
          () => {    
              console.log(this.state.option);
          }
        );
    }
    Home(){
        if(this.props.backHome!==undefined){
            this.props.backHome()
        }
    }
    render() {
        return (
            <div className='store'>
                <div className='back-home' onClick={()=>this.Home()}></div>
                <div className='side'>
                    <div className='item'>STATUS</div>
                    <div className='item'>PRICE</div>
                    <div className='item'>QUANTITY</div>
                    <div className='item'>CURRENCY</div>
                </div>
                <div className='con'>
                    <div className='top'>
                        <div className='search'>
                            <input className='search-input' placeholder='Search by name or trait'></input>
                        </div>
                        <div className='option' onClick={()=>this.setState({
                            optionShow:!this.state.optionShow
                        },
                        () => {    
                            console.log(this.state.optionShow);
                        })}>
                            <div className='value'>{this.state.option}</div>
                            <div className='img-area'>
                                <img alt="" className="up-down" src="images/down.svg"></img>
                            </div>
                            <div className={['vlist',this.state.optionShow?'active':''].join(' ')} >
                                <div className='item' onClick={()=>this.changeOption('Price high to low')}>Price high to low</div>
                                <div className='item' onClick={()=>this.changeOption('Price low to high')}>Price low to high</div>
                                <div className='item' onClick={()=>this.changeOption('Most popular')}>Most popular</div>
                                <div className='item' onClick={()=>this.changeOption('Latest')}>Latest</div>
                            </div> 
                        </div>
                    </div>
                    <div className='list'>
                        {
                            this.state.list.map(item => {
                                return(
                                    <div key={item.id} className='item'>
                                        <div className='img'>
                                            <img alt="" className="item-img" src={item.img}></img> 
                                        </div>
                                        <div className='name'>{item.name}</div>
                                        <div className='status'>{item.status}</div>
                                    </div>
                                )
                                 
                            })     

                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default NFTstore