import { HashRouter, Route, Routes, Navigate } from 'react-router-dom'
import Index from './pages/index'
import GenesisNft from './pages/genesisNft'
import Membership from './pages/membership'
import NFTstore from './pages/NFTstore'
import Tokenomics from './pages/tokenomics'



import './App.css'
function App() {
    return (
        <HashRouter>
            <Routes>
                <Route exact  path="/genesisNft" element={ <GenesisNft /> } />
                <Route exact  path="/membership" element={ <Membership /> } />
                <Route exact  path="/nftstore" element={ <NFTstore /> } />
                <Route exact  path="/tokenomics" element={ <Tokenomics /> } />

                {/* 路由精确匹配"/index"，跳转index页面 */}
                <Route exact path="/" element={<Index />} />
                {/* 未匹配，则跳转默认页面 */}
                <Route path="*" element={<Navigate to="/index" />} />
            </Routes>
        </HashRouter>
    )
}

export default App