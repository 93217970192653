import React, { Component } from 'react'
import './index.less'

class Header extends Component {
    constructor (props) {
        super(props)
        this.state = {
            isShow:false,
        }
      }
    componentDidMount(){
        console.log("componentDidMount()")
        if(this.props.onRef!==undefined){
            this.props.onRef(this)
        }
    }
    changeShow = type =>{
        this.setState(
            { isShow: false },
            () => {
                console.log(this.state.isShow);
            }
        );
    }
    goOutLink(url){
        window.open(url,'_blank')
    }
    menu = () => {
        if(this.props.openMenu!==undefined){
            let isShow = this.state.isShow
            if(isShow===false){
                this.props.openMenu(true)
            }else{
                this.props.openMenu(false)
            }
            this.setState(
                { isShow: !isShow },
                () => {
                    console.log(this.state.isShow);
                }
            );
        }
    }
    member = () => {
        if(this.props.openMember!==undefined){
            this.props.openMember(true)
        }
    }
    openModal = () => {
        if(this.props.openModal!==undefined){
            this.props.openModal(true)
        }
    }
    home = () => {
        window.open('/','_self')
    }
    render() {
        return (
            <div className='header'>
                <div onClick={()=>this.openModal(true)} className='ibutton download-game'>
                </div>
                {/* <div onClick={()=>this.goOutLink('https://play.google.com/store/apps/details?id=world.infiblue.chat')} className='ibutton download-game'>
                </div> */}
                <div className='logo-area'>
                  <img alt="" className="logo"  onClick={()=>this.home()} src="images/logo.png"></img>  
                </div>
                <div className='ibutton membership' onClick={()=>this.member()}>
                </div>
                <div className={['top-menu',this.state.isShow?'active':''].join(' ')} onClick={()=>this.menu()}></div>
           </div>
        )
    }
}

export default Header