import React, { Component } from 'react'
import './modal.less'
class Footer extends Component {
    constructor (props) {
        super(props)
        this.state = {
            isShow:true,
        }
      }

      goOutLink(url){
        window.open(url,'_blank')
    }
    showModal = (value) => {
        
        if(this.props.openModal!==undefined){
            this.props.openModal(value)
        }
    }
    render() {
        return (
            <div className="modal-container" style={{display:this.state.isShow?'flex':'none'}}>
                <div className="modal">
                    <img alt="" className="down-img" src="images/7inch-tablet-screenshot1.5.jpg"></img>
                    <div className="modal__details">
                        <div className="two">
                            <div className="item">
                                <img alt='' className='item-img' src='images/ios-download.png'></img>
                                <div className="item-text">
                                    <div className="item-title" onClick={()=>this.goOutLink('https://apps.apple.com/us/app/infibluechat/id6463155126')}>
                                    Download iOS app
                                    </div>
                                    <div className="item-sub-title">
                                    Scan to download
                                    </div>
                                </div>
                            </div>
                            <div className='item mleft'>
                                <img alt='' className='item-img' src='images/google-play.png'></img>
                                <div className="item-text">
                                    <div className="item-title" onClick={()=>this.goOutLink('https://play.google.com/store/apps/details?id=world.infiblue.chat')}>
                                    Download Android app
                                    </div>
                                    <div className="item-sub-title">
                                    Scan to download from google play
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='one'>
                            <div className='one-title'>Android app apk</div>
                            <div onClick={()=>this.goOutLink('https://m.im.infiblue.world/download/android.html')} className='ibutton download'></div>
                            
                        </div>
                    </div>
                    
                    

                    <div className="link-2" onClick={()=>this.showModal(false)}></div>

                </div>
            </div>
        )
    }
}

export default Footer