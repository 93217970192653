import React, { Component } from 'react'
import './index.less'
class Footer extends Component {
    goOutLink(url){
        window.open(url,'_blank')
    }
    render() {
        return (
            <div className='footer'>
                <div className='icon twitter' onClick={() => this.goOutLink('https://twitter.com/InfiblueNFT') } ></div>
                {/* <div className='icon game' onClick={() => this.goOutLink('https://discord.gg/svyR68KhXJ') }></ div>
                <div className='icon telegram' onClick={() => this.goOutLink('https://t.me/infiblueworld') } ></div> */}
            </div>
        )
    }
}

export default Footer