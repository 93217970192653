import React, { Component } from 'react'
import './index.less'
class GenesisNft extends Component {
    constructor (props) {
        super(props)
        this.state = {
            step:1,
            sub:1, //sub step
        }
    }
    goNext(){
        let step = this.state.step;
        let sub = this.state.sub;
        if(sub===1){
            this.setState(
                { sub: sub+1},
                () => {
                    console.log(this.state.sub);
                }
            );            
        }
        if(sub===2){
            this.setState(
                { step: step+1},
                () => {
                    console.log(this.state.step);
                }
            );   
        }

    }
    goPrev(){
        let sub = this.state.sub
        if(sub>1){
            this.setState(
                { sub: sub-1},
                () => {
                    console.log(this.state.sub);
                }
            );            
        }else if(sub===1){
            this.Home()
        }
    }
    Home(){
        if(this.props.backHome!==undefined){
            this.props.backHome()
        }
    }
    render() {
        let stepHtml;
        let subHtml;
        if(this.state.sub===1){
            subHtml = (<div className='mid'>
            <div className='item'>
                <div className='img-area'>
                    <img alt="" className="img" src='images/genesis-nft/Rectangle.png'></img> 
                </div>
                <div className='name'>FIRE</div>
                <div className='status'>GENESIS NFT</div>
            </div>
        <div className='item'>
                <div className='img-area'>
                    <img alt="" className="img" src='images/genesis-nft/Rectangle-3.png'></img> 
                </div>
                <div className='name'>WATER</div>
                <div className='status'>GENESIS NFT</div>
            </div>
            <div className='item'>
                <div className='img-area'>
                    <img alt="" className="img" src='images/genesis-nft/Rectangle-1.png'></img> 
                </div>
                <div className='name'>WOOD</div>
                <div className='status'>GENESIS NFT</div>
            </div>                     
        </div>  )
        }else if(this.state.sub===2){
            subHtml = (<div className='mid'>
            <div className='item'>
                <div className='img-area'>
                    <img alt="" className="img" src='images/genesis-nft/Rectangle-1.png'></img> 
                </div>
                <div className='name'>WOOD</div>
                <div className='status'>GENESIS NFT</div>
            </div>
            <div className='item'>
                <div className='img-area'>
                    <img alt="" className="img" src='images/genesis-nft/Rectangle-4.png'></img> 
                </div>
                <div className='name'>WIND</div>
                <div className='status'>GENESIS NFT</div>
            </div>
            <div className='item'>
                <div className='img-area'>
                    <img alt="" className="img" src='images/genesis-nft/Rectangle-2.png'></img> 
                </div>
                <div className='name'>EARTH</div>
                <div className='status'>GENESIS NFT</div>
            </div>                        
        </div>)
        }


        if(this.state.step===1){
            stepHtml = (<div className='step step1'>
                <div className='t'>INFIBLUE GENESIS NFTS</div>
                <div className='sub'>The Genesis Nfts From The Best Metaverse Game</div>
                <div className='switch'>
                    <div className='prev' onClick={()=> this.goPrev()}></div>
                    {subHtml}
                    <div className='next'  onClick={()=> this.goNext()}></div>
                </div>
            </div>)
        }else if(this.state.step===2){
            stepHtml = (<div className='step step2'>
                <div className='t'>THE RESULT OF  GENESIS NFT MINT</div>
                <img alt=""  onClick={()=> this.goNext()}  className="img chart" src='images/genesis-nft/chart.svg'></img> 
            </div>)
        }else if(this.state.step===3){
            stepHtml = (<div className='step step3'>
                <div className='t'>INFIBLUE GENESIS NFTS</div>
                <div className='sub'>
                    <div>Genesis NFTs are the very first NFTs Infiblue will issue.</div>
                    <div>There are 5 types of Genesis NFTs with a total of 10,000, including Wind, Fire, Earth, Wood, and Water. </div>
                    <div>There are three types of categories for describing how rare a Genesis NFT of each type is:</div>
                </div>
                <div className='mid'>
                    <div className='item'>
                        <div className='img-area'>
                            <img alt="" className="img" src='images/genesis-nft/normal-nft.svg'></img> 
                        </div>
                    </div>
                    <div className='item'>
                        <div className='img-area'>
                            <img alt="" className="img" src='images/genesis-nft/rare-nft.svg'></img> 
                        </div>
                    </div>
                    <div className='item'>
                        <div className='img-area'>
                            <img alt="" className="img" src='images/genesis-nft/extremely-rare.svg'></img> 
                        </div>
                    </div>                        
                </div>
            </div>)
        }        
        return (
            <div className='genesis'>
                {stepHtml}    
            </div>
        )
    }
}

export default GenesisNft