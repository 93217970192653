import React, { Component } from 'react'
import './index.less'
class Homemenu extends Component {
    constructor (props) {
        super(props)
        this.state = {
            index:1,
        }
      }
    page = (value) => {
        if(this.props.toPage!==undefined){
            this.props.toPage(value)
            
            this.setState(
                { index: value },
                () => {
                    console.log(this.state.index);
                }
            );
        }
    }
    member = () => {
        if(this.props.openMember!==undefined){
            this.props.openMember(true)
        }
    }

    openModal = () => {
        if(this.props.openModal!==undefined){
            this.props.openModal(true)
        }
    }
    goOutLink(url){
        window.open(url,'_blank')
    }
    render() {
        return (
            <div className='home-menu' >
                <div className='item' onClick={()=>this.page(1)}>project</div>
                <div className='item' onClick={()=>this.page(3)}>gAME PLAY</div>
                <div className='item' onClick={()=>this.page(4)}>sOCIALFI</div>
                <div className='item' onClick={()=>this.page(5)}>NFT SHOWROOM</div>
                <div className='item' onClick={()=>this.page(6)}>TOKENOMICS</div>
                <div className='item' onClick={()=>this.page(7)}>ROADMAP</div>
                <div className='item' onClick={()=>this.goOutLink('https://medium.com/@InfiblueWorld/infiblue-whitepaper-v1-0-8bb685def026')}>WHITEPAPER</div>
                <div className='item' onClick={()=>this.page(8)}>Founding team</div>
                <div className='item' onClick={()=>this.page(9)}>OUR PARTNERS</div>

                <div className='item menu-m'>
                    {/* <div className='ibutton download-game' onClick={()=>this.goOutLink('https://play.google.com/store/apps/details?id=world.infiblue.chat')}>
                    </div> */}
                    <div className='ibutton download-game' onClick={()=>this.openModal(true)}>
                    </div> 
                    <div className='ibutton membership' onClick={()=>this.member()}>
                    </div>
                </div>
            </div>
        )
    }
}
export default Homemenu