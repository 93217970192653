import React, { Component } from 'react'
import './index.less'
import 'animate.css'
class Loading extends Component {
    constructor (props) {
        super(props)
        this.state = {
            isShow:true,
        }
      }
   
    componentDidMount () {
        const timer = setTimeout(() => {
            this.changeShow()
          }, 2000);
          return () => {
            clearInterval(timer);
          };
    }
    changeShow(){
        // console.log("changeshow-loading")
        this.setState(
            { isShow: false },
            () => {    
                //console.log(this.state.isShow);
            }
          );
    }
    render() {
        return (
            <div className='loading'>
                <div key="amache" className={['animate__animated',this.state.isShow?'':'zoomBigOut'].join(' ')}>
                    
                    <img alt="" className="loading-img" src="images/loading.svg"></img>
                </div>
            </div>
        )
    }
}
export default Loading