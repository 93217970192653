import React, { Component } from 'react'
import './index.less'
class PropertyNft extends Component {
    constructor (props) {
        super(props)
        this.state = {
            list:[
                {name:'Coming Soon',status:'Coming Soon',img:'images/property/1.png',price:22,quantity:50,currency:2323,id:1},
                {name:'Coming Soon',status:'Coming Soon',img:'images/property/2.png',price:22,quantity:50,currency:2323,id:2},
                {name:'Coming Soon',status:'Coming Soon',img:'images/property/3.png',price:22,quantity:50,currency:2323,id:3},
                {name:'Coming Soon',status:'Coming Soon',img:'images/property/4.png',price:22,quantity:50,currency:2323,id:4},
                {name:'Coming Soon',status:'Coming Soon',img:'images/property/5.png',price:22,quantity:50,currency:2323,id:5},
                {name:'Coming Soon',status:'Coming Soon',img:'images/property/6.png',price:22,quantity:50,currency:2323,id:6},
                {name:'Coming Soon',status:'Coming Soon',img:'images/property/7.png',price:22,quantity:50,currency:2323,id:7},
            ],
        }
    }
    Home(){
        if(this.props.backHome!==undefined){
            this.props.backHome()
        }
    }
    render() {
        return (
            <div className='storeB'>
                <div className='back-home' onClick={()=>this.Home()}></div>
                <div className='con'>
                    <div className='list'>
                        {
                            this.state.list.map(item => {
                                return(
                                    <div key={item.id} className='item'>
                                        <div className='img'>
                                            <img alt="" className="item-img" src={item.img}></img> 
                                        </div>
                                        <div className='name'>{item.name}</div>
                                        <div className='status'>{item.status}</div>
                                    </div>
                                )
                                 
                            })     

                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default PropertyNft